* {
  margin: 0;
  padding: 0;
}

.project-nav__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.project-nav__title-icon {
  display: inline-flex;
}

.project-nav__likes-join {
  display: inline-flex;
  justify-content: flex-end;
}

.project-nav__profile {
  padding-left: 3rem;
  padding-right: 5px;
}
.project-nav__profile img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.project-nav__title {
  display: inline;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: capitalize;
  padding-left: 30px;
  padding-top: 20px;
  margin-bottom: 0px;
}

.project-nav__like {
  max-height: 39px;
  max-width: 25px;
  padding-top: 20px;
}
.project-nav__likes {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.project-nav__number-likes {
  font-size: 1rem;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  padding: 16px 30px 0px 5px;

  color: #9e0000;
}

.project-nav__button {
  background: #9e0000;
  border-radius: 9px;
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  cursor: pointer;
  color: #ffffff;

  margin-top: 7px;
  margin-bottom: 7px;
  padding-top: 11px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 45px;
}

@media only screen and (max-width: 650px) {
  .project-nav__arrow {
    // width: 30px;
    // height: 39px;
    width: 1.875rem;
    height: 2.438rem;
    padding: 16px 0px 0px 8px;
  }
  .project-nav__profile {
    padding-left: 0.7rem;
    padding-right: 1px;
    padding-top: 0.8rem;
  }
  .project-nav__profile img {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
  }

  .project-nav__title {
    font-size: 1rem;
    padding-left: 7px;
    flex-wrap: nowrap;
    text-transform: lowercase;
    text-transform: capitalize;
    padding-top: 20px;
    margin-top: 5px;
  }
  .project-nav__like {
    // max-height: 36px;
    // max-width: 32px;
    max-height: 2.25rem;
    max-width: 2rem;
    padding-top: 20px;
    padding-left: 12px;
  }

  .project-nav__number-likes {
    font-size: 0.938rem;
    line-height: 17px;
    text-align: left;
    padding: 9px 8px 0px 5px;
    margin-top: 13px;
    white-space: nowrap;
  }
  .project-nav__likes {
    padding-top: 5px;
  }
  .project-nav__button {
    font-size: 0.875rem;
    line-height: 29px;
    white-space: nowrap;
    margin-top: 13px;
    margin-right: 4px;
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .projectnav-likes {
    display: none;
  }
}
