
h2 {
  font-weight: 900;
  padding-top: 30px;
  font-size: 30px;
}
.project_details_flex {
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: space-between;
  min-height: 80vh;
  align-items: center;
  gap: 2rem;
}
.details__container {
  width: 100vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  padding-left: 12rem;
  padding-right: 5%;
}
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 650px) {
  .details__container {
    padding: 5%;
    justify-content: center;
  }

  .project_details_flex {
    display: block;
  }
}

@media (max-width: 760px) {
  .container__details {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .details__container {
    min-height: auto;
  }
}

@media (min-width: 1040px) and (max-width: 1250px) {
  .details__container {
    padding-left: 16%;
  }
}
@media (min-width: 920px) and (max-width: 1040px) {
  .details__container {
    padding-left: 18%;
  }
}
@media (min-width: 780px) and (max-width: 920px) {
  .details__container {
    padding-left: 20%;
  }
}
@media (min-width: 650px) and (max-width: 780px) {
  .details__container {
    padding-left: 25%;
  }
}
