.footer_main {
  display: flex;
  background-color: #a81a1a;
}
.footer_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  padding-top: 1%;
  padding-bottom: 1%;
}
.footer__made {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
}
.footer__content {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.github {
  cursor: pointer;
  transform: scale(0.8);
  pointer-events: none;
}
.linkedin {
  cursor: pointer;
  transform: scale(0.8);
  pointer-events: none;
}
.mail {
  cursor: pointer;
  transform: scale(0.8);
  pointer-events: none;
}
.bubble_6 {
  pointer-events: none;
  max-width: 356px;
}
.bubble_7 {
  pointer-events: none;
}
@media (max-width: 1000px) {
  .bubble_6 {
    display: none;
  }
  .bubble_7 {
    display: none;
  }
  .footer_main {
    justify-content: center;
  }
  .footer_icons {
    padding-top: 3%;
    padding-bottom: 3%;
  }
}
