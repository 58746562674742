.projects_landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 5rem;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}
.projects_landing button:focus {
  outline: none;
}
.projects_landing button {
  color: #9e0000;
  font-size: 1rem;
}
.projects_landing button:hover {
  background-color: #e77e7e4d;
}
.projects_cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 2rem;
}
.card-2 {
  pointer-events: none;
  opacity: 60%;
}
.projects_landing h4 {
  font-weight: 700;
  color: #4a4a4a;
}
.projects_landing h3 {
  color: var(--primaryColor);
  font-size: 30px;
  font-weight: 800;
  padding-top: 20px;
}

.projects_landing body {
  background: #dce1df;
  // color: #4f585e;
  text-rendering: optimizeLegibility;
  //   margin-top: 50px;
}

.cards {
  margin: 80px auto;
  max-width: 960px;
  text-align: center;
  display: inline-block;
  margin: 25px;
}

.card {
  background: var(--primaryColor);
  display: inline-block;
  margin: 8px;
  max-width: 300px;
  perspective: 1000;
  position: relative;
  text-align: center;
  transition: all 0.3s 0s ease-in;
  width: 300px;
  z-index: 1;
  border-radius: 10px;
  cursor: pointer;

  img {
    // padding: -10;
    width: 100%;
    height: 230px;
    border-radius: 10px;
    object-fit: cover;
  }

  .card__image-holder {
    background: rgba(0, 0, 0, 0.1);
    height: 0;
    padding-bottom: 75%;
  }

  div.card-title {
    background: var(--primaryColor);
    padding: 8px 15px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    z-index: 0;
    color: #fff;

    h2 {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -0.05em;
      margin: 0;
      padding: 0;

      small {
        display: block;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.025em;
      }
    }
  }
}
@media screen and (max-width: 2000px) {
  .card {
    float: none;
    margin: 0 auto;
  }
}
@media (max-width: 1201px) {
  .cards {
    display: block !important;
  }
  .projects_landing h3 {
    padding-top: 80px;
  }
}
@media (max-width: 390px) {
  .cards {
    margin-left: 0px;
    margin-right: 0px;
  }
  .card {
    margin-left: 0px;
    margin-right: 0px;
  }
}
